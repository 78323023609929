.food section {
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .food section {
    min-height: 0;
    height: auto !important;
  }
}
.food section:nth-child(1) .fp-bg {
  background-image: url(../../../public/images/food/banner.jpg);
}
@media screen and (max-width: 767px) {
  .food section:nth-child(1) {
    min-height: 40vh !important;
  }
}
.food .sandpaper-text .container {
  max-height: 300px;
  align-items: center;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .en .food .sandpaper-text {
    padding-left: 0;
    padding-right: 0;
  }
  .en .food .sandpaper-text:not(.fp-completely) .container {
    touch-action: none;
  }
  .en .food .sandpaper-text .container {
    align-items: flex-start;
  }
}
.en .food .sandpaper-text .container {
  max-height: calc(100vh - 120px);
}
.ja .food .sandpaper-text .container, .ja .food .sandpaper-text p {
  writing-mode: vertical-rl;
}
.food .sandpaper-text__text {
  max-width: 100%;
  overflow: scroll;
  padding: 0 40px;
}
.food__slider {
  padding: 100px 0;
  height: auto;
}
.food__slider__container {
  max-width: 100vw;
  overflow: hidden;
  position: relative;
}
.food__slider .slick-slide {
  margin: 0 -1px;
}
.food__slider img {
  height: 70vh;
  min-height: 300px;
  width: auto;
  display: block;
}
@media screen and (max-width: 767px) {
  .food__slider .slick-track {
    display: flex;
  }
  .food__slider img {
    min-height: 0;
    max-width: 85vw;
    height: auto;
  }
}

.section__text {
  max-width: 700px;
}

