main.top section:nth-child(1) .fp-bg {
  background-image: url(../../../public/images/home/home-1.jpg);
}

main.top section:nth-child(2) .fp-bg {
  background-image: url(../../../public/images/home/home-2.jpg);
}

main.top section:nth-child(3) .fp-bg {
  background-image: url(../../../public/images/home/home-3.jpg);
}

main.top section:nth-child(4) .fp-bg {
  background-image: url(../../../public/images/home/home-4.jpg);
}

.top__banner {
  min-height: 100vh;
  align-items: flex-end;
}
.top__banner .logo-container {
  justify-content: center;
}
.top__banner .logo-container img {
  max-width: 172px;
}
@media screen and (max-width: 767px) {
  .top__banner .logo-container img {
    max-width: 136px;
  }
}

.section.fp-table {
  align-items: center;
}
.section__text {
  max-width: 700px;
}

