$smartphone-breakpoint: 'screen and (max-width: 767px)';
$tablet-breakpoint: 'screen and (max-width: 989px)';

.food {
  section {
    // height: auto !important;
    overflow: hidden;

    @media #{$smartphone-breakpoint} {
      min-height: 0;
      height: auto !important;
    }

    &:nth-child(1) {
      .fp-bg {
        background-image: url(../../../public/images/food/banner.jpg);
      }
      
      @media #{$smartphone-breakpoint} {
        min-height: 40vh !important;
      }
    }
  }
  .sandpaper-text {
    // height: auto !important;
    // min-height: 0;
    .container {
      max-height: 300px;
      align-items: center;
      display: flex;
      justify-content: center;

    }
    .en & {
      @media #{$smartphone-breakpoint} {
        padding-left: 0;
        padding-right: 0;

        &:not(.fp-completely) {
          .container {
            touch-action: none;
          }
        }

        .container {
          align-items: flex-start;

        }
      }
      .container {
        max-height: calc(100vh - 120px);
      }
    }
    .container, p {
      .ja & {
  writing-mode: vertical-rl;
}
    }

    &__text {
      max-width: 100%;
      overflow: scroll;
      padding: 0 40px;
    }
  }

  &__slider {
    &__container {
      max-width: 100vw;
      overflow: hidden;
      position: relative;
    }
    padding: 100px 0;
    height: auto;

    .slick-slide {
      margin: 0 -1px;
    }

    img {
      height: 70vh;
      min-height: 300px;
      width: auto;
      display: block;

    }
    @media #{$smartphone-breakpoint} {
      .slick-track {
        display: flex;
      }

      img {
        min-height: 0;
        max-width: 85vw;
        height: auto;
      }
    }
  }
}

.section {
  &__text {
    max-width: 700px;
  }
}