$smartphone-breakpoint: 'screen and (max-width: 767px)';

@mixin bgImage($key) {
  main.top section:nth-child(#{$key}) .fp-bg {
    background-image: url(../../../public/images/home/home-#{$key}.jpg);
  }

  // @media #{$smartphone-breakpoint} {
  //   @if $key == 1 {
  //     main.top section:nth-child(#{$key}) .fp-bg {
  //       background-image: url(../../../public/images/home/home-#{$key}-sp.jpg);
  //     }
  //   }
  // }
}

@each $key in 1,2,3,4 {
  @include bgImage($key);
}

.top {
  &__banner {
    min-height: 100vh;
    align-items: flex-end;
    .logo-container {
      justify-content: center;

      img {
        max-width: 172px;

        @media #{$smartphone-breakpoint} {
          max-width: 136px;
        }
      }
    }
  }
}

.section {
  &.fp-table {
    align-items: center;
  }

  &__text {
    max-width: 700px;
  }
}