@font-face {
  font-family: "YuGothic";
  src: url(../public/yugothic.ttf);
}
.en * {
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
  font-style: normal;
}

* {
  font-family: "YuGothic", sans-serif;
  font-display: swap;
}

@media screen and (max-width: 767px) {
  .tablet-and-above {
    display: none;
  }
}

@media not screen and (max-width: 767px) {
  .sp-only {
    display: none;
  }
}

html {
  scroll-behavior: smooth;
}

body.animating, body.animating * {
  pointer-events: none;
  touch-action: none;
}

@keyframes loadingImage {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    filter: blur(15px);
    opacity: 0;
  }
}
.logoLoader > img {
  z-index: 100;
  max-height: 60vh;
}

body {
  position: relative;
  background-image: url(../public/images/sandpaper.jpg);
  background-repeat: repeat;
}

img {
  max-width: 100%;
}

section {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 108px 32px;
  min-height: 100vh;
  height: 100vh !important;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
section .container {
  position: relative;
}
section:not(.section--sandpaper):not(section.sandpaper-text) * {
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.7);
}
@media not screen and (max-width: 767px) {
  section {
    padding-top: 42px;
  }
}
@media screen and (max-width: 989px) {
  section {
    padding: 60px 16px;
    height: 80vh;
  }
}

.section--sandpaper {
  height: auto;
}
.section--sandpaper + .section--sandpaper {
  padding-top: 0;
}
@media screen and (max-width: 767px) {
  #fullPage .section--sandpaper {
    padding: 20px 16px;
  }
}
@media not screen and (max-width: 767px) {
  .section--sandpaper .logo-container img {
    max-height: 50vh;
  }
}
.section--left-right .container {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 36px;
}
.section--left-right .container p {
  line-height: 1.5;
  margin-bottom: 2em;
}
@media screen and (max-width: 767px) {
  .section--left-right .container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .section--left-right .container img {
    max-height: 280px;
  }
  .section--left-right .container img.neutralize-bottom-padding {
    margin-bottom: -40px;
  }
  .section--left-right .container .section__left {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }
}
.section__decoration {
  overflow: hidden;
}
.section__decoration img {
  height: auto;
}
@media not screen and (max-width: 989px) {
  .section__decoration img {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
@media screen and (max-width: 989px) {
  .section__decoration {
    padding: 0;
    height: auto;
    min-height: 0;
  }
  .section__decoration img {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .section#access .section__right img {
    max-height: calc(100vh - 40px - 300px);
  }
  .en .section#access .section__right img {
    max-height: calc(100vh - 40px - 360px);
  }
}
@media screen and (max-width: 767px) {
  .section#footer-reserve h2 {
    margin-top: 0;
  }
  .section#footer-reserve .logo-container img {
    max-height: calc(100vh - 40px - 360px);
  }
  .section#footer-reserve a.style-link {
    margin-top: 0;
  }
}

.container {
  width: 100%;
  max-width: 1080px;
  margin: auto;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

* {
  color: white;
}

h1 {
  margin-top: 0;
  margin-left: 1em;
  font-size: 1.5rem;
}

h2 {
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
}

p {
  font-size: 15px;
  line-height: 2;
  letter-spacing: 0.15em;
}
@media screen and (max-width: 767px) {
  p {
    font-size: clamp(8px, 3vw, 15px);
  }
}

a {
  text-decoration: none;
}

a.style-link {
  display: inline-block;
  position: relative;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding: 8px 0 22px 22px;
  margin-top: 2em;
  width: 160px;
}
a.style-link::before, a.style-link::after {
  content: "";
  background: white;
  position: absolute;
  transition: 0.5s;
}
a.style-link::before {
  width: 1px;
  height: 100%;
  left: 8px;
  top: 0;
}
a.style-link::after {
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 8px;
}
a.style-link:hover::before {
  top: -8px;
}
a.style-link:hover::after {
  left: 8px;
}
@media screen and (max-width: 767px) {
  .section--sandpaper a.style-link {
    margin-top: 1em;
  }
}
.en a.style-link {
  width: auto;
}

.header {
  position: fixed;
  padding: 1px 0;
  z-index: 100;
  width: 100%;
  top: 0;
  background: transparent;
}
.header ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header ul li {
  list-style: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3em;
  margin: 0 2em;
}
.header ul li a {
  position: relative;
}
.header ul li a::after {
  content: "";
  position: absolute;
  top: calc(100% + 2px);
  left: calc(5% - 1px);
  width: 90%;
  height: 2px;
  transition: 0.5s;
  border-radius: 2px;
}
.header ul li.active a::after {
  background: white;
}
.header ul li a:hover {
  cursor: pointer;
}
.header ul li.language a {
  display: flex;
  align-items: center;
}
@media not screen and (max-width: 767px) {
  .header ul li.language a svg {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .header ul li.language {
    position: absolute;
    bottom: 32px;
    right: 16px;
    font-size: 10px;
    letter-spacing: 1px;
  }
  .header ul li.language svg {
    margin-left: 8px;
  }
}
@media not screen and (max-width: 767px) {
  .header {
    background-image: url(../public/images/sandpaper.jpg);
    background-repeat: repeat;
  }
  .header .container {
    position: relative;
  }
  .header .container ul {
    margin: 12px 0;
  }
  .header__button, .header__mobile-screen__logo, .header__mobile-screen__button, .header__mobile-screen__address, .header li > span {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .header button {
    padding: 0;
    border: 0;
    background-color: transparent;
  }
  .header > .header__button {
    position: fixed;
    top: 16px;
    right: 16px;
  }
  .header > .header__button span {
    display: block;
    width: 39px;
    height: 5px;
    border-radius: 100px;
    background-color: #84755D;
  }
  .header > .header__button span + span {
    margin-top: 4px;
  }
  .header__mobile-screen {
    position: fixed;
    left: 0;
    top: 0;
    width: calc(100vw + 1px);
    height: 100vh;
    box-sizing: border-box;
    transform: translateX(100vw);
    transition: 0.5s;
    background-image: url(../public/images/header-menu.jpg);
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 32px 16px;
  }
  .header__mobile-screen__button {
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    left: -20px;
    align-self: flex-end;
  }
  .header__mobile-screen__button span {
    width: 30px;
    height: 3px;
    background: white;
    position: absolute;
    top: calc(50% - 1px);
    transform: rotate(45deg) translate(-10px, 10px);
  }
  .header__mobile-screen__button span:last-child {
    transform: rotate(-45deg) translate(-10px, -10px);
  }
  .header__mobile-screen .container {
    flex: 1 1 auto;
  }
  .header__mobile-screen ul {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 0;
    padding-top: 30px;
  }
  .header__mobile-screen ul li {
    letter-spacing: 0.5em;
    font-size: 15px;
    line-height: 1.5;
    margin: 0 0 1em;
  }
  .header__mobile-screen ul li > span {
    margin-right: 2em;
  }
  .header__mobile-screen ul li:last-child {
    margin-bottom: 0;
  }
  .header__mobile-screen__logo {
    align-self: flex-end;
    width: 74px;
    margin-right: 40px;
  }
  .header__mobile-screen__address {
    font-size: 10px;
    line-height: 1.5;
  }
  .header--open .header__mobile-screen {
    transform: translateX(0);
  }
}

* {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
*::-webkit-scrollbar {
  display: none;
}

.fp-overflow {
  width: 100%;
}

.fp-bg {
  background-size: cover;
  background-position: center 80%;
  position: absolute;
  inset: 0;
  width: 100vw;
  height: 100vh;
}

.fp-watermark {
  display: none !important;
}

#access {
  position: relative;
}
#access #access-point {
  position: absolute;
  top: 30px;
}

@supports (-webkit-touch-callout: none) {
  @media screen and (max-width: 767px) {
    .header ul li.language {
      bottom: 92px;
    }
    .header__mobile-screen {
      padding-bottom: 80px;
    }
  }
  @media screen and (max-width: 767px) and (max-width: 767px) {
    section#access a.style-link {
      margin-top: 0;
    }
    section#access .section__right img {
      margin-top: -40px;
      max-height: calc(100vh - 100px - 340px) !important;
    }
    .en section#access .section__right img {
      max-height: calc(100vh - 100px - 400px) !important;
    }
  }
  @media screen and (max-width: 767px) and (max-width: 767px) {
    section#footer-reserve h2 {
      margin-top: 0;
    }
    section#footer-reserve .logo-container img {
      max-height: calc(100vh - 100px - 420px) !important;
    }
    section#footer-reserve a.style-link {
      margin-top: 0;
    }
    section#footer-reserve .section__left {
      padding-bottom: 30px;
    }
  }
}

