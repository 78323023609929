$smartphone-breakpoint: 'screen and (max-width: 767px)';
$tablet-breakpoint: 'screen and (max-width: 989px)';

@mixin bgImage($key) {
  main.gallery section:nth-child(#{$key}) .fp-bg {
    @if $key == 6 {
      background-image: url(../../../public/images/home/home-1.jpg);
    } @else {
      background-image: url(../../../public/images/gallery/gallery-#{$key}.jpg);
    }
  }

  @media #{$smartphone-breakpoint} {
    // @if $key == 1 {
    //   main.gallery section:nth-child(#{$key}) {
    //     background-image: url(../../../public/images/gallery/gallery-#{$key}-sp.jpg);
    //   }
    // }

    @if $key == 1 {
      main.gallery section:nth-child(#{$key}) .fp-bg {
        background-position: 80% 0;
      }
    }
    @if $key == 2 {
      main.gallery section:nth-child(#{$key})  .fp-bg {
        background-position: 80% 0;
      }
    }
  }
}

@each $key in 1,2,3,4,5,6,7,8 {
  @include bgImage($key);
}

main.gallery {
  section {
    &:not(.section--sandpaper) {
      &::before {
        content: '';
        position: absolute;
        inset: 0;
        height: 100%;
        background: rgba(0,0,0,0.3);
      }
      
      .container * {
        .ja & {
  writing-mode: vertical-rl;
}
      }

      &.fp-table {
        align-items: flex-start;
      }
  
      .container {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
  
        .section__text {
          max-height: 55%;
          max-width: 100%;
          overflow: scroll;
          
          .ja & {
            min-height: 390px;
          }

          p.season {
            text-indent: -2.2em;
            margin: 0;
            padding-top: 2.2em;
          }
        }
  
        @media #{$smartphone-breakpoint} {
          align-items: center;
          justify-content: center;
        }
      }
    }

    @media #{$smartphone-breakpoint} {
      &:nth-child(1), &:nth-child(2) {
        min-height: 100vh;
      }

      &:nth-child(1) .container {
        align-items: flex-end;
      }

      &:nth-child(2) .container {
        position: relative;
        // top: -40px;
        align-items: flex-start;
      }

      &:nth-child(3), &:nth-child(4) {
        max-height: 60vh;
      }
    }
  }

}