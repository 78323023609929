.chef section:not(.section__decoration) {
  height: auto !important;
  min-height: 0;
}
.chef section * {
  text-shadow: none !important;
}
.chef .section__decoration:first-child img {
  object-position: center 75%;
}
.chef__intro {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.ja .chef__intro h1, .ja .chef__intro__profile {
  writing-mode: vertical-rl;
}
.chef__intro h1 {
  text-align: right;
  align-self: flex-end;
  margin: 0;
  letter-spacing: 5px;
}
.chef__intro h1 small {
  margin-bottom: 1em;
}
.en .chef__intro h1 * {
  display: block;
}
.en .chef__intro h1 small {
  margin-bottom: 0.25em;
}
@media not screen and (max-width: 767px) {
  .chef__intro .container {
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    grid-template-rows: 320px 460px auto;
    grid-gap: 48px 36px;
    align-items: center;
  }
  .chef__intro__image {
    grid-row: 1/span 2;
  }
  .chef__intro__profile p:first-child {
    margin-right: 0;
  }
  .chef__intro__profile-two {
    grid-column: 1/span 2;
    padding: 0 10%;
  }
}
@media screen and (max-width: 767px) {
  .chef__intro {
    padding-top: 0;
    position: relative;
  }
  .chef__intro .container > img.sp-only {
    margin-top: 36px;
  }
  .chef__intro__image img.sp-only {
    min-height: 135vw;
    object-fit: cover;
    display: block;
  }
  .chef__intro h1 {
    position: absolute;
    top: 125vw;
    transform: translateY(-100%);
    right: 16px;
  }
  .chef__intro__profile, .chef__intro__profile-two {
    padding: 32px 32px 0 32px;
  }
  .chef__intro__profile {
    overflow: scroll;
    max-width: 100%;
    box-sizing: border-box;
    max-height: 275px;
    margin: auto;
  }
}
@media not screen and (max-width: 767px) {
  .chef__gallery .container {
    display: grid;
    grid-gap: 48px;
    grid-template-columns: 1fr 1fr;
  }
  .chef__gallery .container img:first-child {
    grid-column: 1/span 2;
  }
}
@media screen and (max-width: 767px) {
  .chef__gallery {
    padding-bottom: 0;
  }
  .chef__gallery .container img {
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: -8px;
    width: 100vw;
    max-width: 100vw;
  }
  .chef__gallery .container img:first-child {
    display: none;
  }
}

