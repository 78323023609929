main.gallery section:nth-child(1) .fp-bg {
  background-image: url(../../../public/images/gallery/gallery-1.jpg);
}

@media screen and (max-width: 767px) {
  main.gallery section:nth-child(1) .fp-bg {
    background-position: 80% 0;
  }
}
main.gallery section:nth-child(2) .fp-bg {
  background-image: url(../../../public/images/gallery/gallery-2.jpg);
}

@media screen and (max-width: 767px) {
  main.gallery section:nth-child(2) .fp-bg {
    background-position: 80% 0;
  }
}
main.gallery section:nth-child(3) .fp-bg {
  background-image: url(../../../public/images/gallery/gallery-3.jpg);
}

main.gallery section:nth-child(4) .fp-bg {
  background-image: url(../../../public/images/gallery/gallery-4.jpg);
}

main.gallery section:nth-child(5) .fp-bg {
  background-image: url(../../../public/images/gallery/gallery-5.jpg);
}

main.gallery section:nth-child(6) .fp-bg {
  background-image: url(../../../public/images/home/home-1.jpg);
}

main.gallery section:nth-child(7) .fp-bg {
  background-image: url(../../../public/images/gallery/gallery-7.jpg);
}

main.gallery section:nth-child(8) .fp-bg {
  background-image: url(../../../public/images/gallery/gallery-8.jpg);
}

main.gallery section:not(.section--sandpaper)::before {
  content: "";
  position: absolute;
  inset: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.ja main.gallery section:not(.section--sandpaper) .container * {
  writing-mode: vertical-rl;
}
main.gallery section:not(.section--sandpaper).fp-table {
  align-items: flex-start;
}
main.gallery section:not(.section--sandpaper) .container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
main.gallery section:not(.section--sandpaper) .container .section__text {
  max-height: 55%;
  max-width: 100%;
  overflow: scroll;
}
.ja main.gallery section:not(.section--sandpaper) .container .section__text {
  min-height: 390px;
}
main.gallery section:not(.section--sandpaper) .container .section__text p.season {
  text-indent: -2.2em;
  margin: 0;
  padding-top: 2.2em;
}
@media screen and (max-width: 767px) {
  main.gallery section:not(.section--sandpaper) .container {
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  main.gallery section:nth-child(1), main.gallery section:nth-child(2) {
    min-height: 100vh;
  }
  main.gallery section:nth-child(1) .container {
    align-items: flex-end;
  }
  main.gallery section:nth-child(2) .container {
    position: relative;
    align-items: flex-start;
  }
  main.gallery section:nth-child(3), main.gallery section:nth-child(4) {
    max-height: 60vh;
  }
}

