$smartphone-breakpoint: 'screen and (max-width: 767px)';
$tablet-breakpoint: 'screen and (max-width: 989px)';

.chef {
  section:not(.section__decoration) {
    height: auto !important;
    min-height: 0;
  }

  section {
    * {
      text-shadow: none !important;
    }

  }
  .section__decoration:first-child {
    img {
      object-position: center 75%;
    }
  }

  &__intro {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    h1, &__profile {
      .ja & {
  writing-mode: vertical-rl;
}
    }

    h1 {
      text-align: right;
      align-self: flex-end;
      margin: 0;
      letter-spacing: 5px;

      small {
        margin-bottom: 1em;
      }

      .en & {
        * {
          display: block;
        }
        small {
          margin-bottom: 0.25em;
        }
      }
    }

    @media not #{$smartphone-breakpoint} {
      .container {
        display: grid;
        grid-template-columns: 1.25fr 1fr;
        grid-template-rows: 320px 460px auto;
        grid-gap: 48px 36px;
        align-items: center;
      }

      &__image {
        grid-row: 1 / span 2;
      }

      &__profile {
        p:first-child {
          margin-right: 0;
        }
      }

      &__profile-two {
        grid-column: 1 / span 2;
        padding: 0 10%;
      }
    }

    @media #{$smartphone-breakpoint} {
      padding-top: 0;
      position: relative;

      .container > img.sp-only {
        margin-top: 36px;
      }

      &__image {
        img.sp-only {
          min-height: 135vw;
          object-fit: cover;
          display: block;
        }
      }

      h1 {
        position: absolute;
        top: 125vw;
        transform: translateY(-100%);
        right: 16px;
      }

      &__profile, &__profile-two {
        padding: 32px 32px 0 32px;
      }

      &__profile {
        overflow: scroll;
        max-width: 100%;
        box-sizing: border-box;
        max-height: 275px;
        margin: auto;
      }
    }
  }

  &__gallery {
    @media not #{$smartphone-breakpoint} {
      .container {
        display: grid;
        grid-gap: 48px;
        grid-template-columns: 1fr 1fr;

        img {
          &:first-child {
            grid-column: 1 / span 2;
          }
        }
      }
    }

    @media #{$smartphone-breakpoint} {
      padding-bottom: 0;
      .container {
        img {
          margin-left: -16px;
          margin-right: -16px;
          margin-bottom: -8px;
          width: 100vw;
          max-width: 100vw;

          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}