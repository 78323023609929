@font-face {
  font-family: 'YuGothic';
  src: url(../public/yugothic.ttf);
}

.en * {
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
  font-style: normal;
}

$smartphone-breakpoint: 'screen and (max-width: 767px)';
$tablet-breakpoint: 'screen and (max-width: 989px)';

* {
  font-family: 'YuGothic', sans-serif;
  font-display: swap;
}

.tablet-and-above {
  @media #{$smartphone-breakpoint} {
    display: none;
  }
}

.sp-only {
  @media not #{$smartphone-breakpoint} {
    display: none;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  &.animating {
    & , * {
      pointer-events: none;
      touch-action: none;
    }
  }
}

@keyframes loadingImage {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    filter: blur(15px);
    opacity: 0;
  }
}


.logoLoader {
  > img {
    z-index: 100;
    max-height: 60vh;
  }
}

body {
  position: relative;
  // &::before {
  //   content: '';
  //   position: absolute;
  //   inset: 0;
  //   height: 100%;
  //   background: rgba(darken(rgb(151, 127, 69), 70%), 0.3);
  // }
  background: {
    image: url(../public/images/sandpaper.jpg);
    repeat: repeat;
  }
}

img {
  max-width: 100%;
}

section {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 108px 32px;
  min-height: 100vh;
  height: 100vh !important;
  position: relative;

  background: {
    size: cover;
    position: center;
    repeat: no-repeat;
  }

  .container {
    position: relative;
  }

  &:not(.section--sandpaper):not(section.sandpaper-text) {
    * {
      text-shadow: 0 0 6px rgba(0,0,0,0.7);
    }
  }

  @media not #{$smartphone-breakpoint} {
    padding-top: 42px;
  }

  @media #{$tablet-breakpoint} {
    padding: 60px 16px;
    height: 80vh;
  }
}

.section {
  &--sandpaper {
    height: auto;

    & + & {
      padding-top: 0;
    }

    @media #{$smartphone-breakpoint} {
      #fullPage & {
        padding: 20px 16px;
      }
    }
    
    @media not #{$smartphone-breakpoint} {
      .logo-container {
        img {
          max-height: 50vh;
        }
      }
    }
  }

  &--left-right {
    .container {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      grid-gap: 36px;
      
      p {
        line-height: 1.5;
        margin-bottom: 2em;
      }

      @media #{$smartphone-breakpoint} {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;

        img {
          max-height: 280px;

          &.neutralize-bottom-padding {
            margin-bottom: -40px;
          }
        }

        .section__left {
          width: 100%;
          padding: 0 16px;
          box-sizing: border-box;
        }
      }
    }
  }

  &__decoration {
    overflow: hidden;
    img {
      height: auto;
    }
    @media not #{$tablet-breakpoint} {
      img {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    @media #{$tablet-breakpoint} {
      padding: 0;
      height: auto;
      min-height: 0;
      img {
        display: block;
      }
    }
  }

  &#access {
    @media #{$smartphone-breakpoint} {
      .section__right img {
        max-height: calc(100vh - 40px - 300px);
        
        .en & {
          max-height: calc(100vh - 40px - 360px);
        }
      }
    }
  }

  &#footer-reserve {
    @media #{$smartphone-breakpoint} {
      h2 {
        margin-top: 0;
      }
      .logo-container img {
        max-height: calc(100vh - 40px - 360px);
      }

      a.style-link {
        margin-top: 0;
      }
    }
  }
}

.container {
  width: 100%;
  max-width: 1080px;
  margin: auto;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

* {
  color: white;
}

h1 {
  margin-top: 0;
  margin-left: 1em;
  font-size: 1.5rem;
}

h2 {
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
}

p {
  font-size: 15px;
  line-height: 2;

  letter-spacing: 0.15em;

  @media #{$smartphone-breakpoint} {
    font-size: clamp(8px, 3vw, 15px);
  }
}

a {
  text-decoration: none;
}

a.style-link {
  display: inline-block;
  position: relative;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding: 8px 0 22px 22px;
  margin-top: 2em;
  width: 160px;

  &::before, &::after {
    content: '';
    background: white;
    position: absolute;
    transition: 0.5s;
  }

  &::before {
    width: 1px;
    height: 100%;
    left: 8px;
    top: 0; 
  }

  &::after {
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 8px; 
  }

  &:hover {
    &::before {
      top: -8px;
    }

    &::after {
      left: 8px;
    }
  }

  @media #{$smartphone-breakpoint} {
    .section--sandpaper & {
      margin-top: 1em;
    }
  }

  .en & {
    width: auto;
  }
}

.header {
  position: fixed;
  padding: 1px 0;
  z-index: 100;
  width: 100%;
  top: 0;
  background: transparent;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      list-style: none;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.3em;
      margin: 0 2em;
      
      a {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: calc(100% + 2px);
          left: calc(5% - 1px);
          width: 90%;
          height: 2px;
          transition: 0.5s;
          border-radius: 2px;
        }
      }


      &.active {
        a::after {
          background: white;
        }
      }

      a:hover {
        cursor: pointer;
      }

      &.language {
        a {
          display: flex;
          align-items: center;

          svg {
            @media not #{$smartphone-breakpoint} {
              display: none;
            }
          }
        }

        @media #{$smartphone-breakpoint} {
          position: absolute;
          bottom: 32px;
          right: 16px;
          font-size: 10px;
          letter-spacing: 1px;

          svg {
            margin-left: 8px;
          }
        }
      }
    }
  }

  @media not #{$smartphone-breakpoint} {
    // position: sticky;
    background: {
      image: url(../public/images/sandpaper.jpg);
      repeat: repeat;
    }

    .container {
      position: relative;

      ul {
        margin: 12px 0;
      }
    }

    &__button, &__mobile-screen__logo, &__mobile-screen__button, &__mobile-screen__address, li > span {
      display: none;
    }
  }

  @media #{$smartphone-breakpoint} {
    button {
      padding: 0;
      border: 0;
      background-color: transparent;
    }
    > .header__button {
      position: fixed;
      top: 16px;
      right: 16px;
      

      span {
        display: block;
        width: 39px;
        height: 5px;
        border-radius: 100px;
        background-color: #84755D;

        & + span {
          margin-top: 4px;
        }
      }
    }
    &__mobile-screen {
      position: fixed;
      left: 0;
      top: 0;
      width: calc(100vw + 1px);
      height: 100vh;
      box-sizing: border-box;
      // height: -webkit-fill-available;
      transform: translateX(100vw);
      transition: 0.5s;
      background: {
        image: url(../public/images/header-menu.jpg);
        size: cover;
      };
      
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 32px 32px 16px;

      &__button {
        display: block;
        position: relative;
        width: 20px;
        height: 20px;
        left: -20px;
        align-self: flex-end;

        span {
          width: 30px;
          height: 3px;
          background: white;
          position: absolute;
          top: calc(50% - 1px);
          transform: rotate(45deg) translate(-10px, 10px);

          &:last-child {
            transform: rotate(-45deg) translate(-10px, -10px);
          }
        }
      }

      .container {
        flex: 1 1 auto;
      }

      ul {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 0;
        padding-top: 30px;

        li {
          letter-spacing: 0.5em;
          font-size: 15px;
          line-height: 1.5;
          margin: 0 0 1em;
          > span {
            margin-right: 2em;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &__logo {
        align-self: flex-end;
        width: 74px;
        margin-right: 40px;
      }

      &__address {
        font-size: 10px;
        line-height: 1.5;
      }


      .header--open & {
        transform: translateX(0);
      }
    }
  }
}

* {
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  
    &::-webkit-scrollbar {
      display: none;
    }
}

.fp-overflow {
  width: 100%;
}

.fp-bg {
  background-size: cover;
  background-position: center 80%;
  position: absolute;
  inset: 0;
  width: 100vw;
  height: 100vh;
}

.fp-watermark {
  display: none !important;
}

#access {
  position: relative;

  #access-point {
    position: absolute;
    top: 30px;
  }
}

@supports (-webkit-touch-callout: none) {
  @media #{$smartphone-breakpoint} {
    .header {
      ul li.language {
        bottom: 92px;
      }
  
      &__mobile-screen {
        padding-bottom: 80px;
      }
    }
  
    section {
      &#access {
        @media #{$smartphone-breakpoint} {
          a.style-link {
            margin-top: 0;
          }
  
          .section__right img {
            margin-top: -40px;
            max-height: calc(100vh - 100px - 340px) !important;
            
            .en & {
              max-height: calc(100vh - 100px - 400px) !important;
            }
          }
        }
      }
    
      &#footer-reserve {
        @media #{$smartphone-breakpoint} {
          h2 {
            margin-top: 0;
          }
          .logo-container img {
            max-height: calc(100vh - 100px - 420px) !important;
          }
    
          a.style-link {
            margin-top: 0;
          }

          .section__left {
            padding-bottom: 30px;
          }
        }
      }
    }
  }
}